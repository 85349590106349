import type { ZudokuConfig } from "zudoku";
import { sidebar } from "./sidebar";

const config: ZudokuConfig = {
  topNavigation: [
    { id: "docs", label: "Documentation" }
  ],
  redirects: [
    { from: "/", to: "/docs" }
  ],
  docs: {
    files: "/pages/**/*.{md,mdx}",
  },
  sidebar,
  page: {
    logo: {
      src: {
        light: "/sasto-light.png",
        dark: "/sasto-dark.png",
      }
    }
  }
};

export default config;
